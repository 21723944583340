// @ts-ignore
import { ref } from 'vue'

import { getReportList } from '@/apis/lambda/report'
import { FlyHttp } from '@/utils/Http'
import { Report } from '@/apis/entity/Report'
import moment from 'moment'

export function useReportList() {
  const reportList = ref<Report[]>([])
  const isLoading = ref(false)
  const isFinished = ref(false)

  const refreshReportList = () => {
    console.log('dd')
  }

  function initReportList(patientId) {
    FlyHttp
      .request(getReportList, { options: { patientId: patientId, status: 2 }})
      .then(resp => {
        reportList.value = resp.data
        reportList.value.forEach(row => {
          row.examinationDate = moment(row.examinationDate).format('YYYY-MM-DD HH:mm:ss')
        })
        isLoading.value = false
        isFinished.value = true
      })
  }

  return { reportList, isLoading, isFinished, refreshReportList, initReportList }
}